import React from "react"

const AboutMeIcons = ({ heading }) => (
  <>
    {heading === "Digital Marketer" && (
      <svg
        className="w-16 mx-auto mb-6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          fill="#343a40"
          d="M17.223 7.03c-1.584-3.686-4.132-6.49-5.421-5.967-2.189.891 1.304 5.164-9.447 9.533-.929.379-1.164 1.888-.775 2.792.388.902 1.658 1.801 2.587 1.424.161-.066.751-.256.751-.256.663.891 1.357.363 1.604.928l1.158 2.66c.219.502.715.967 1.075.83l2.05-.779c.468-.178.579-.596.436-.924-.154-.355-.786-.459-.967-.873-.18-.412-.769-1.738-.938-2.156-.23-.568.259-1.031.97-1.104 4.894-.512 5.809 2.512 7.475 1.834 1.287-.525 1.025-4.259-.558-7.942zm-.551 5.976c-.287.115-2.213-1.402-3.443-4.267-1.231-2.863-1.076-5.48-.79-5.597.286-.115 2.165 1.717 3.395 4.58 1.231 2.863 1.124 5.167.838 5.284z"
        />
      </svg>
    )}
    {heading === "Jamstack Enthusiast" && (
      <svg
        className="w-16 mx-auto mb-6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          fill="#343a40"
          d="M5.719 14.75a.997.997 0 01-.664-.252L-.005 10l5.341-4.748a1 1 0 011.328 1.495L3.005 10l3.378 3.002a1 1 0 01-.664 1.748zm8.945-.002L20.005 10l-5.06-4.498a.999.999 0 10-1.328 1.495L16.995 10l-3.659 3.252a1 1 0 001.328 1.496zm-4.678 1.417l2-12a1 1 0 10-1.972-.329l-2 12a1 1 0 101.972.329z"
        />
      </svg>
    )}
    {heading === "WordPress Implementer" && (
      <svg
        className="w-16 mx-auto mb-6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 666.67 666.67"
      >
        <path
          fill="#343a40"
          d="M333.33 20a311.93 311.93 0 01175.18 53.5 314.19 314.19 0 01113.54 137.88 313 313 0 01-28.88 297.13 314.19 314.19 0 01-137.88 113.54 313 313 0 01-297.13-28.88A314.19 314.19 0 0144.62 455.29 313 313 0 0173.5 158.16a314.94 314.94 0 0184.66-84.66A311.93 311.93 0 01333.33 20m0-20C149.24 0 0 149.24 0 333.33s149.24 333.34 333.33 333.34 333.34-149.24 333.34-333.34S517.43 0 333.33 0z"
        />
        <path
          fill="#343a40"
          d="M55.56 333.32c0 110 63.89 205 156.56 250l-132.51-363a276.71 276.71 0 00-24.05 113zm465.3-14c0-34.34-12.34-58.1-22.9-76.6-14.08-22.89-27.29-42.26-27.29-65.15 0-25.53 19.36-49.3 46.65-49.3 1.23 0 2.4.15 3.59.23a276.69 276.69 0 00-187.58-72.94c-97 0-182.42 49.79-232.09 125.19 6.52.21 12.67.34 17.88.34 29 0 74-3.53 74-3.53 15-.88 16.74 21.12 1.78 22.89 0 0-15.05 1.76-31.79 2.64L264.29 504l60.8-182.31-43.28-118.6c-15-.88-29.13-2.64-29.13-2.64-15-.88-13.22-23.77 1.76-22.89 0 0 45.86 3.53 73.16 3.53 29 0 74-3.53 74-3.53 15-.88 16.74 21.12 1.77 22.89 0 0-15.07 1.76-31.79 2.64L472 501.68l28.65-90.8c12.74-39.68 20.21-67.8 20.21-91.57zm-182.65 38.3l-83.35 242.17a277.85 277.85 0 00170.72-4.43 25.15 25.15 0 01-2-3.83zm238.88-157.55a212 212 0 011.91 28.56c0 28.17-5.28 59.86-21.13 99.49L473 573.41c82.58-48.14 138.12-137.61 138.12-240.09a276.41 276.41 0 00-34.03-133.25z"
        />
      </svg>
    )}
  </>
)

export default AboutMeIcons
