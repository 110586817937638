import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const ContactMe = () => (
  <StaticQuery
    query={graphql`
      query contactMeQuery {
        markdownRemark(
          frontmatter: { templateKey: { eq: "banners-include" } }
        ) {
          frontmatter {
            contact_me {
              heading
              subheading
              button_label
              button_link
              background_image_top {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              background_image_bottom {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({
      markdownRemark: {
        frontmatter: {
          contact_me: {
            heading,
            subheading,
            button_label,
            button_link,
            background_image_top,
            background_image_bottom,
          },
        },
      },
    }) => {
      return (
        <section className="my-12">
          <BackgroundImage
            Tag="div"
            fluid={[
              background_image_top.childImageSharp.fluid,
              background_image_bottom.childImageSharp.fluid,
            ]}
            className="bg-dark bg-no-repeat bg-auto py-12"
            style={{
              backgroundPosition: "0 0, 0 100%",
              backgroundSize: "auto 60px",
            }}
          >
            <div className="container mx-auto px-2">
              <div className="flex flex-col lg:flex-row lg:flex-wrap lg:items-center lg:content-center -mx-2">
                <div className="w-full lg:w-1/3 px-2">
                  <h2 className="text-3xl text-center font-semibold text-white mx-auto mb-6">
                    {heading}
                  </h2>
                </div>
                <div className="w-full lg:w-1/3 px-2 pb-8 lg:pb-0">
                  <p className="text-xl text-center font-semibold text-white mx-auto">
                    {subheading}
                  </p>
                </div>
                <div className="w-full lg:w-1/3 px-2 text-center">
                  <Link
                    to={`/${button_link}/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer bg-transparent hover:bg-white text-xl text-white font-semibold hover:text-black py-2 px-4 border border-white hover:border-transparent rounded transition duration-300 ease-in-out"
                  >
                    {button_label}
                  </Link>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </section>
      )
    }}
  />
)

export default ContactMe
