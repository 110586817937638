import React from "react"
import BackgroundImage from "gatsby-background-image"

import { toHtmlAst } from "../../utils/toHtmlAst"
import { renderAst } from "../../utils/renderAst"
import AboutMeIcons from "./aboutMeIcons"

const AboutMe = ({
  data: {
    heading,
    copy_markdown,
    background_image_top,
    background_image_bottom,
    skills,
  },
}) => {
  return (
    <section>
      <BackgroundImage
        Tag="div"
        fluid={[
          background_image_top.childImageSharp.fluid,
          background_image_bottom.childImageSharp.fluid,
        ]}
        className="flex flex-wrap bg-dark bg-no-repeat bg-auto px-4 lg:px-10 pt-24 lg:pt-24 pb-64"
        style={{
          backgroundPosition: "0 0, 0 100%",
          backgroundSize: "auto 60px",
        }}
      >
        <div className="container mx-auto">
          <h2 className="text-3xl text-center text-white mx-auto mb-6 font-medium">
            {heading}
          </h2>
          {renderAst(
            {
              p: ({ children }) => (
                <p className="max-w-4xl text-xl text-center text-white font-light mx-auto mb-4">
                  {children}
                </p>
              ),
            },
            toHtmlAst(copy_markdown)
          )}
        </div>
      </BackgroundImage>
      <div className="container relative z-10 mx-auto flex flex-wrap px-4 -mt-48 lg:px-10 pb-12">
        <div className="flex flex-wrap text-center border rounded border-gray-300">
          {skills.map(({ heading, copy_markdown }, index) => {
            return (
              <div
                key={index}
                className={`w-full lg:w-1/3 ${
                  index === 1 &&
                  "border-t border-b lg:border-t-0 lg:border-b-0 lg:border-r lg:border-l lg:border-gray-300"
                } bg-white pt-12 pb-6 px-4`}
              >
                <AboutMeIcons heading={heading} />
                <h3 className="text-2xl font-semibold capitalize mb-4">
                  {heading}
                </h3>
                {renderAst(
                  {
                    p: ({ children }) => <p className="">{children}</p>,
                    h4: ({ children }) => (
                      <h4 className="font-semibold pt-8 pb-3">{children}</h4>
                    ),
                    h6: ({ children }) => (
                      <h6 className="font-semibold pt-8">{children}</h6>
                    ),
                    ul: ({ children }) => (
                      <ul className="pb-4 list-unstyled">{children}</ul>
                    ),
                  },
                  toHtmlAst(copy_markdown)
                )}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default AboutMe
