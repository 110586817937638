import React from "react"
import Img from "gatsby-image/withIEPolyfill"

const Hero = ({ data: { heading, subheading, image, image_alt } }) => {
  return (
    <section className="container mx-auto flex flex-col px-4 md:px-10 py-12 md:py-24">
      <h1 className="text-3xl md:text-5xl text-center leading-tight mx-auto mb-6 font-medium">
        {heading}
      </h1>
      <p className="text-xl text-center font-light mx-auto mb-4">
        {subheading}
      </p>
      <Img
        className="mx-auto"
        fixed={image.childImageSharp.fixed}
        alt={image_alt}
        fadeIn={false}
        loading="eager"
      />
    </section>
  )
}

export default Hero
