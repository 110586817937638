import React from "react"
import { Link } from "gatsby"

import { toHtmlAst } from "../../utils/toHtmlAst"
import { renderAst } from "../../utils/renderAst"
import RecentWorkProject from "./recentWorkProject"

const RecentWork = ({ data: { heading, copy_markdown, work } }) => {
  return (
    <section className="container mx-auto flex flex-col px-4 md:px-10 py-12">
      <h2 className="text-3xl text-center mx-auto mb-6 font-medium">
        {heading}
      </h2>
      {renderAst(
        {
          p: ({ children }) => (
            <p className="max-w-2xl text-xl text-center font-light mx-auto mb-8">
              {children}
            </p>
          ),
          a: ({ href, children }) => (
            <Link className="font-bold hover:underline" to={`/${href}/`}>
              {children}
            </Link>
          ),
        },
        toHtmlAst(copy_markdown)
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {work.map((project, index) => (
          <RecentWorkProject data={project} key={index} index="0" />
        ))}
      </div>
    </section>
  )
}

export default RecentWork
