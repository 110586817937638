import React from "react"
import { Link } from "gatsby"

const BodyAst = {
  a: ({ href, children }) => {
    if (href.startsWith("https://") || href.startsWith("http://")) {
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      )
    } else {
      return <Link to={`/${href}/`}>{children}</Link>
    }
  },
}

export default BodyAst
