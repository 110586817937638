import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/home/hero"
import AboutMe from "../components/home/aboutMe"
import RecentWork from "../components/home/recentWork"
import ContactMe from "../components/banners/contactMe"
import HomeArticle from "../components/home/homeArticle"

export const IndexPageTemplate = ({
  data: {
    markdownRemark: {
      frontmatter: { slug, title, description, hero, about_me, recent_work },
      body,
    },
  },
}) => {
  return (
    <Layout>
      <SEO title={title} description={description} pathname={slug} />
      <Hero data={hero} />
      <AboutMe data={about_me} />
      <RecentWork data={recent_work} />
      <HomeArticle data={body} />
      <ContactMe />
    </Layout>
  )
}

export default IndexPageTemplate

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        slug
        title
        description
        hero {
          heading
          subheading
          image {
            childImageSharp {
              fixed(width: 398) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
          image_alt
        }
        about_me {
          heading
          copy_markdown
          background_image_top {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          background_image_bottom {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          skills {
            heading
            copy_markdown
          }
        }
        recent_work {
          heading
          copy_markdown
          work {
            primary_copy
            secondary_copy
            button_label
            button_link
            image {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            image_alt
          }
        }
      }
      body: htmlAst
    }
  }
`
