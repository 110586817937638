import React from "react"

import { renderAst } from "../../utils/renderAst"
import BodyAst from "../ast/bodyAst"

const HomeArticle = ({ data }) => {
  const bodyLength = data.children.length
  const halfway = Math.ceil(bodyLength / 2)
  const column1 = {
    type: "root",
    data: { quirksMode: false },
    children: data.children.slice(0, halfway),
  }
  const column2 = {
    type: "root",
    data: { quirksMode: false },
    children: data.children.slice(halfway, bodyLength),
  }
  const renderColumn = (column) => renderAst(BodyAst, column)
  return (
    <section>
      <div className="container mx-auto px-4 md:px-10 py-12">
        <div className="flex flex-col md:flex-row md:space-x-6">
          <div className="prose prose-lg max-w-none flex-grow text-gray-500 md:w-1/2">
            {renderColumn(column1)}
          </div>
          <div className="prose prose-lg max-w-none flex-grow text-gray-500 md:w-1/2">
            {renderColumn(column2)}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeArticle
