import React, { useState } from "react"
import Img from "gatsby-image/withIEPolyfill"

const RecentWorkProject = ({
  data: {
    primary_copy,
    secondary_copy,
    button_label,
    button_link,
    image,
    image_alt,
  },
  index,
}) => {
  const [projectHover, setProjectHover] = useState(false)

  return (
    <div
      className="relative rounded-md bg-dark overflow-hidden cursor-default"
      onMouseEnter={() => setProjectHover(true)}
      onMouseLeave={() => setProjectHover(false)}
      role="button"
      tabIndex={index}
    >
      <div className="relative pb-2/3">
        <Img
          className="w-full h-full"
          fluid={image.childImageSharp.fluid}
          alt={image_alt}
          objectFit="cover"
          style={{
            position: "absolute",
            transform: projectHover ? "scale(1.1)" : "scale(1)",
            transition: ".25s ease-in-out",
          }}
        />
      </div>
      <div
        className={`${
          projectHover ? "opacity-100" : "opacity-0"
        } bg-dark absolute inset-0 flex flex-col justify-center items-center text-center p-6`}
        style={{
          transition: ".5s ease",
        }}
      >
        <p className="text-white mb-4">{secondary_copy}</p>
        <a
          href={button_link}
          target="_blank"
          rel="noopener noreferrer"
          className="block cursor-pointer bg-transparent hover:bg-white text-white font-semibold hover:text-black py-2 px-4 border border-white hover:border-transparent rounded transition duration-300 ease-in-out"
        >
          {button_label}
        </a>
      </div>
      <div className="p-4 transition duration-300 ease-in-out">
        <p className="text-white pointer-events-none">{primary_copy}</p>
      </div>
    </div>
  )
}

export default RecentWorkProject
